import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/elmentviews/IndexView.vue')
  },
  {
    path: '/categorymanager',
    name: 'category',
    component: () => import('../views/elmentviews/CategoryView.vue')
  },
  {
    path: '/bookmanager',
    name: 'book',
    component: () => import('../views/elmentviews/MainView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/elmentviews/LoginView.vue')
  },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('../views/elmentviews/TestView.vue')
  // }
]



const router = new VueRouter({
  routes
})

export default router
